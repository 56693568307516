.container
{
    width: 100%;
}
.logo
{   
    width: 100%;
}
h2
{
    font-family: 'Roboto', sans-serif;
    text-align: center;
    color: #00B5F3;
}
.h3
{
    font-family: 'Roboto', sans-serif;
    width: 100%;
    color: red;
    font-size: 16px;
}
h4
{
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 30px;
    color: #00B5F3;
    font-size: 18px;
}
.field
{
margin: 7px;
}
.imagesHome
{
    position: relative;
    padding-top: 35px;
}
.imagesPages
{
    width: 100%;
    position: relative;
    margin-top: 20px ;
    text-align: center;
    align-items: center;  
}
.Pages
{
    width: 100%;
    position: absolute;
    margin-top: 20px ;
    padding-left: 30px;
    padding-right: 30px;
    align-items: center;
}
.bannerHeader
{
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    justify-content: space-around;
    background: #00B5F3;
    height: 80px;
    text-align: center;
    align-items: center;
    padding-top: 0px;
}
.bannerPages
{
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    justify-content: space-around;
    height: 100%;
    text-align: center;
    align-items: center;
    padding-top: 10px;
}
.bannerHeader .title
{
    font-family: 'Roboto', sans-serif;
    font-size: 32px;
}
.waves
{
    position: relative;
    bottom: -50px;
    width: 100%;
    height: 100px;
    background: #fff; 
}
.waves .wave
{
    position: absolute;
    width: 100%;
    height: 100px;
    background: url(../NavBar/wave.png);
    background-size: 1000px 100px;
}
.waves .wave#wave1
{
    z-index: 1000;
    opacity: 1;
    bottom: 0;
    animation: animateWave 4s linear infinite;
}
.waves .wave#wave2
{
    z-index: 999;
    opacity: 0.5;
    bottom: 10px;
    animation: animateWave_02 4s linear infinite;
}
.waves .wave#wave3
{
    z-index: 1000;
    opacity: 0.2;
    bottom: 15px;
    animation: animateWave 3s linear infinite;
}
.waves .wave#wave4
{
    z-index: 999;
    opacity: 0.7;
    bottom: 20px;
    animation: animateWave_02 3s linear infinite;
}
@keyframes animateWave
{
    0%
    {
        background-position-x: 1000px ;
    }
    100%
    {
        background-position-x: 0px ;
    }
}
@keyframes animateWave_02
{
    0%
    {
        background-position-x: 0px ;
    }
    100%
    {
        background-position-x: 1000px ;
    }
}
@media screen and (max-width: 800px){
    .bannerHeader .title{display: none;}
} 


 