.containerForm{
    display: flex;
    justify-content: center;
}
.form{
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    font-size: 16px;
    text-align: left;
    width: 500px;
    margin-top: 10px;
    border-style:solid;
    border-color: rgb(230, 230, 230);
    border-radius: 10px;
    padding: 35px;
}
.form > div{
    margin-bottom: 15px;
}
.field{
    border: none;
    width: 100%;
    background-color: rgb(219, 219, 219);
    font-size: 13px;
    margin-left: 0px;
}
button{
    border: none;
    color: #fff;
    background-color: #00B5F3;
    width: 100%;
    height: 35px;
    border-radius: 5px;
    font-size: 16px;
}
.error{
    color: red;
    font-size: 12px;
    text-align: right;
}
.success{
    font-size: 16px;
    margin-top: 15px;
    color: green;
}
.txtform{
    font-family: Arial, Helvetica, sans-serif;
    color:#00B5F3;
    font-size: 16px;
}
 