* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
  }
  
  .clock-holder {
    width: 100%;
    background: #fff;
    position: relative;
  }
  
  .stopwatch {
    padding: 40px 0px;
    text-align: center;
  }
  .stopwatch span{
    background:#00b6f3c2;
    color: #fff;
    display: inline-block;
    font-family: monospace;
    font-size: 30px;
    padding: 20px;
    border-radius: 5px;
    width: 100px;
  }

  .btn-watch{
    background: #00B5F3;
    margin-top: 30px;
    margin-left: 5px;
    border: none;
    padding: 0px 20px;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    width: 150px;
    cursor: pointer; 
  }

  .dots{
    font-size: 30px;
  }
  
  h6{
    color: #7a7a7a;
  }
 
  