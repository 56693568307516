.bannerHeader .btn
{
position: relative;
width: 200px;
height: 40px;
font-size: 21px;
cursor: pointer;
}
.bannerHeader .btn a
{
position: absolute; 
top: 0;
left: 0;
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
background: rgba(255, 255, 255, 0.20);
box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
border-top: 1px solid rgba(255, 255, 255, 0.1);
border-bottom: 1px solid rgba(255, 255, 255, 0.1);
border-radius: 0px;
color: rgb(255, 255, 255);
z-index: 1;
font-weight: 400;
letter-spacing: 1px;
text-decoration: none;
overflow: hidden;
transition: 0.5s;
backdrop-filter: blur(15px);
}
.bannerHeader .btn:hover a
{
    letter-spacing: 3px;
}
.bannerHeader .btn a::before
{
   content: '';
   position: absolute;
   top: 0;
   left: 0;
   width: 50%;
   height: 100%;
   background: linear-gradient(to left, rgba(255, 255, 255, 0.30),
   transparent);
   transform: skewX(-45deg) translateX(0);
   transition: 0.5s; 
}
.bannerHeader .btn:hover a::before
{
    transform: skewX(45deg) translateX(200px);
}
.bannerHeader .btn::before
{
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 5px;
    width: 30px;
    height: 10px;
    background: #27beff;
    border-radius: 10px;
    transition: 0.5s;
    transition-delay: 0s;
}
.bannerHeader .btn:hover:before
{
    bottom: 0%;
    height: 50%;
    width: 80%;
    border-radius: 30px;
    transition-delay: 0.3s;
}
.translateArea{
    width: 100%;
    text-align: center;
    padding: 5px;
}
.translateArea .btnLangueFr{ 
    border-radius: 5px;  
    width: 50px;
    height: 25px;
    cursor: pointer;
}
.translateArea .btnLangueEn{ 
    border-radius: 5px;  
    width: 50px;
    height: 25px;
    cursor: pointer;
}